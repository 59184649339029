import React, { useState, useEffect } from 'react';

import { NavLink, Link, useNavigate } from 'react-router-dom';
import { Navigate } from "react-router-dom";

import axiosClient from '../axios-client';
import { useStateContext } from "../Context/ContextProvider";
import * as Icon from 'react-feather';

// import Plenticar from './images/plenticar.png'
import Offer from '../images/offer.png'
import Dealer from '../images/dealer.png'
import Customer from '../images/customer.png'
import logo from '../images/plenticar.png'
import List from '../images/list.png'
import Setimg from '../images/settings.png'
import AdminImg from '../images/man-2.png'
import logout from '../images/logout.png'

import PalentiCar from '../images/palenticar_white.png'

const SidebarMenu = (props) => {

    // Access the getUserRole function from props
    const { getUserRole, getIsLoading } = props;

    //up state in the default function it is also added
    const { user, token, role, setUser, setToken, setRole, notification } = useStateContext();

    const [isMenuFixed, setIsMenuFixed] = useState(true);

    const handleResize = () => {
        const bodyElement = document.body;
        if (window.innerWidth < 1024) {
            setIsMenuFixed(false);
            bodyElement.classList.remove('layout-menu-fixed');
            bodyElement.classList.add('device');
        } else {
            setIsMenuFixed(true);
            bodyElement.classList.add('layout-menu-fixed');
            bodyElement.classList.remove('device');
        }
    }
    const resize = () => {
    
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }

    const toggleMenu = () => {
        setIsMenuFixed(!isMenuFixed);
        // document.body.classList.remove('layout-menu-fixed');
        const bodyElement = document.body;
        if (isMenuFixed) {
            bodyElement.classList.remove('layout-menu-fixed');
        } else {
            bodyElement.classList.add('layout-menu-fixed');
        }
    }

    const closeMenu = () => {
        setIsMenuFixed(false);
    }

    const onLogout = (e) => {
        e.preventDefault()

        axiosClient.post('/logout')
            .then(() => {
                setUser({})
                setToken(null)
            })
    }

    useEffect(() => {
        console.log('getUserRole====111getRequestLoading',getUserRole,getIsLoading)
        window.addEventListener('load', handleResize);
        resize()
    }, [getUserRole,getIsLoading])

    return (
        <div className={`layout-menu ${isMenuFixed ? 'open' : ''}`}>
            <NavLink to="/">
                    <img src={PalentiCar} alt="Logo" className="logo" />
            </NavLink>
            <button className="menu-toggle" onClick={toggleMenu}>
                <i className={`fa ${isMenuFixed ? 'fa-arrow-left' : 'fa-arrow-right'}`}></i>
            </button>

            {
                getIsLoading != true?
                (
                    <>
                    {role == 'admin' &&
                    <ul className="menu-items">
        
                        <li className="menu-item">
                            <NavLink to="/">
                                <Icon.Home /> Dashboard
                            </NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/dealers">
                                <Icon.Award /> Dealers
                            </NavLink>
                        </li>
        
                        <li className="menu-item">
                            <NavLink to="/customers">
                                <Icon.Users /> Customers
                            </NavLink>
                        </li>
                    
                        <li className="menu-item">
                            <NavLink to="/allcars">
                                <Icon.BarChart />Cars List
                            </NavLink>
                        </li>
        
                         {/* <li className="menu-item">
                            <NavLink to="/offers">
                                <Icon.Tag /> My Offers
                            </NavLink>
                        </li> */}
                        {/* <li className="menu-item">
                            <NavLink to="/setting">
                                <Icon.Settings /> Settings
                            </NavLink>
                        </li> */}
                        <li className="menu-item mt-5" onClick={onLogout} >
                            <NavLink to="/login">
                                <Icon.Power /> Logout
                            </NavLink>
                        </li>
                    </ul>
                    }
        
                    {role != 'admin' &&
                    <ul className="menu-items">
        
                        {/* <li className="menu-item">
                            <NavLink to="/">
                                <Icon.Home /> Dashboard
                            </NavLink>
                        </li> */}
                        {/* <li className="menu-item">
                            <NavLink to="/dealers">
                                <Icon.Award /> Dealers
                            </NavLink>
                        </li>
        
                        <li className="menu-item">
                            <NavLink to="/customers">
                                <Icon.Users /> Customers
                            </NavLink>
                        </li>
                    
                     */}
                       
                        <li className="menu-item">
                            <NavLink to="/cars">
                                <Icon.List /> Cars for Sale
                            </NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/offers">
                                <Icon.Tag /> My Offers
                            </NavLink>
                        </li>
                        {/* <li className="menu-item">
                            <NavLink to="/setting">
                                <Icon.Settings /> Settings
                            </NavLink>
                        </li> */}
                        <li className="menu-item mt-5" onClick={onLogout} >
                            <NavLink to="/login">
                                <Icon.Power /> Logout
                            </NavLink>
                        </li>
                    </ul>
                    }
                    </>
                ):('')
            }

            
        </div>
    );
}

export default SidebarMenu;