import {createBrowserRouter} from "react-router-dom"
import Login from '../Pages/Login'
import Verification from '../Pages/Verification'
import Invitation from '../Pages/Invitation'
import DealerSignup from '../Pages/DealerSignup'
import ResetPassword from '../Pages/ResetPassword'
import ResetPasswordSuccess from '../Pages/ResetPasswordSuccess'
import VerifyUser from '../Pages/VerifyUser'
import ForgotPassword from '../Pages/ForgotPassword'
import { useStateContext } from '../Context/ContextProvider'
// restricted pages
import Customer from '../Pages/Customer'
import Dealers from '../Pages/Dealers'
import List from '../Pages/List'
import Index from '../Pages/Index'
import Offers from "../Pages/Offers"
import Profile from "../Pages/Profile"
import AdminCars from "../Pages/AdminCars"
import AdminCustomerCars from "../Pages/AdminCustomerCars"

import Subscribed from "../Pages/Subscribed"
import Subfailed from "../Pages/Subfailed"
import UpdatePassword from "../Pages/UpdatePassword"


//google map component temp
import MapLocation from "../Components/MapLocation"


import DefaultLayout from "../Components/DefaultLayout";
import GuestLayout from "../Components/GuestLayout";
import React, { useState } from "react"
// import PageNotFound from "../views/PageNotFound"
const router = createBrowserRouter([
    {
      path: '/',
      element: <DefaultLayout/>,
        children: [
            {
                path: '/',
                element: <Index/>
                // element: <List/>
            },
            {
                path: '/dealers',
                element: <Dealers />
            },
            {
                path: '/customers',
                element: <Customer />
            },
            {
                path: '/customers/:customerId?',
                element: <AdminCustomerCars/>
            },
            {
                path: '/profile',
                element: <Profile/>
            },
            {
                path: '/cars/:status?',
                element: <List/>
            },
            {
                path: '/allcars',
                element: <AdminCars/>
            },
            {
                path: '/offers',
                element: <Offers/>
            }
            ,
            {
                path: '/subscribed',
                element: <Subscribed/>
            } ,
            {
                path: '/subfailed',
                element: <Subfailed/>
            },
            {
                path: '/profile-password',
                element: <UpdatePassword/>
            },
            {
                path: '/map',
                element: <MapLocation/>
            }
        ]
    },
    {
        path:'/',
        element: <GuestLayout/>,
        children: [
            {
                path: '/login',
                element: <Login/>
            },
            {
                path: '/verification/:userId/:token',
                element: <Verification/>
            },
            {
                path: '/verification-success',
                element: <ResetPasswordSuccess/>
            },
            {
                path: '/register',
                element: <DealerSignup/>
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword/>
            },
            {
                path: '/reset-password/:userId/:token',
                element: <ResetPassword/>
            },
            {
                path: '/reset-password-success',
                element: <ResetPasswordSuccess/>
            },
            {
                path: '/verify/:token',
                element: <VerifyUser/>
            },
            {
                path: '/invitation/:userId/:token',
                element: <Invitation/>
            },
        ]
    }
    // {
    //     path: '*',
    //     element: <PageNotFound/>
    // }


])

export default router;

function AuthLayout() {
    const { user } = useStateContext();
    const [userRole, setUserRole] = useState(); 
    return user.role
  }
