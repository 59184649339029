import React, { useEffect, useState } from "react";
import { useStateContext } from "../Context/ContextProvider";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

import Monthly from '../images/monthly_updated.jpg'

// import '../CSS/dealer.css'



const Subfailed = (props) => {
  const { user, token, setUser, setToken, notification } = useStateContext();

  const [getComponentLoading, setComponentLoading] = useState(true);

  const [sub, setSub] = useState([]);
  const [subscription, setSubscription] = useState(false);
  const [listCar, setListCar] = useState([]);




  const { msg } = useParams();

  if (msg) {
    toast.error("Payment not Successful", {
      position: "top-right",
      autoClose: 2500,
      theme: 'colored'
    });
  }



  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Subscription</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto mt-5">
            <div className="card">
              <div className="card-header bg-warning text-center pt-3 pb-2">
                <h1 className="text-white">Subscription Failed!</h1>
              </div>
              <div className="card-body text-center pb-4">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <img className="w-100" src={Monthly} />
                  </div>
                  <div className="col-sm-2"></div>
                </div>
                <p>Please pay monthly subscription Fee to continue</p>
                <Link className="btn btn-primary w-50" to="/cars">Go to Payment</Link>
              </div>
            </div>
          </div>
        </div>
        
        {/* <div className="row">
          <div className="col-md-6 mx-auto mt-5">
            <div className="payment">
              <div className="payment_header red color-danger text-center text-warning">
                <h1>Subscription Failed !</h1>
              </div>
              <div className="content">
                <img className="subscripation_img" src={Monthly} />
                <p>Please pay monthly subscription Fee to contine</p>
                <Link className="btn btn-primary w-50" to="/cars">Go to Payment</Link>
              </div>
            </div>
          </div>
        </div> */}

      </div>

    </>
  );
};

export default Subfailed;
