import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axiosClient from "../axios-client";
import InputMask from "react-input-mask";
import * as Icon from 'react-feather';
import { ComponentLoading } from "../Components/ComponentLoading";
import axios from 'axios'

function Profile() {
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: '',
    password: '',
    stripe_id: '',
    avatar: ''
  });
  const [getUserRole, setUserRole] = useState('');
  const [getAvatar, setAvatar] = useState('');

  const [btnLoading, setBtnLoading] = useState(false)
  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getAvatarComponentLoading, setAvatarComponentLoading] = useState(false);

  const getProfile = () => {
    setComponentLoading(true);
    axiosClient.get('/profile')
      .then(({ data }) => {
        // alert(user.email)
        setUserRole(data.role)
        setUserProfile(data.user)
        setComponentLoading(false);
        // console.log(data)
      })
  }

  useEffect(() => {
    getProfile()

    window.addEventListener('addressUpdated', (event) => {
      if (event['detail'].value) {
        getProfile()
      }
    })

  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile({ ...userProfile, [name]: value });
    console.log(e.target.value);
    console.log(userProfile);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true)
    axiosClient.put('/profile', userProfile)
      .then((response) => {
        setBtnLoading(false)
        // 
        window.dispatchEvent(new CustomEvent(
          'refreshProfile', 
          {
              detail: {
                  value:true
              }
          }
        ))
        // 
        // response.json({ message: 'Request received!', response })
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }

  const handleImageUpload = async (event) => {
    setAvatarComponentLoading(true)
    // return;
    // setBtnLoading(true)
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000);

    const selectedFile = event.target.files[0];
    const selectedFileType = selectedFile.type;
    const selectedFileName = selectedFile.name.split(".").shift();
    const selectedFileExtension = selectedFile.name.split(".").pop();
    const selectedFileRandomName = `${timestamp}-${random}.${selectedFileExtension}`

    console.log('handleImageUpload', selectedFile, selectedFileType, selectedFileName, selectedFileExtension, selectedFileRandomName)

    axiosClient.post('/s3-pre-url', {
      file_name: selectedFileRandomName,
      file_type: selectedFileType,
    })
      .then((responsePreUrl) => {
        if (responsePreUrl.data.pre_url) {
          ////////////////////////////////////
          console.log('responsePreUrl.data.pre_url=', responsePreUrl.data.pre_url)
          axios.put(responsePreUrl.data.pre_url, selectedFile)
            .then((response) => {
              setAvatarComponentLoading(false)
              setAvatar(responsePreUrl.data.url)
              // setUserProfile((prevState => ({ ...userProfile, avatar: getAvatar })));
              setUserProfile({ ...userProfile, avatar: responsePreUrl.data.url });
            })
            .catch(err => {
              setAvatarComponentLoading(false)
              var firstErrorMessage = '';
              if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                  position: "bottom-right",
                  autoClose: 1500,
                  theme: "colored",
                });
              } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                  position: "bottom-right",
                  autoClose: 1500,
                  theme: "colored",
                });
              }
              // console.log(err);
            });
          ////////////////////////////////////
        }
      })
      .catch(err => {
        setAvatarComponentLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }


  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Profile</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Profile
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div class="card h-100">
              <div class="card-body">
                <form method="POST" enctype="multipart/form-data">

                  <div class="user-profile-avatar mb-2">
                    <div class="innerContainer">
                      {getAvatarComponentLoading ? (
                          <div className="card loadingCard">
                            <div className="card-body" style={{minHeight: '150px'}}>
                              <ComponentLoading></ComponentLoading>
                            </div>
                          </div>
                        ) : (
                          userProfile.name?
                          (
                              <img className={"symbol-circle bg-" + userProfile.name.slice(0, 1).toUpperCase() + " img-alt"} src={userProfile.avatar} alt={userProfile.name.slice(0, 1).toUpperCase()} />
                          ):(
                              userProfile.email?
                              (
                                  <img className={"symbol-circle bg-" + userProfile.email.slice(0, 1).toUpperCase() + " img-alt"} src={userProfile.avatar} alt={userProfile.email.slice(0, 1).toUpperCase()} />
                              ):(
                                  <img className={"symbol-circle bg-" + "N/A" + " img-alt"} src={userProfile.avatar} alt={"N/A"} />
                              )
                          )
                        )
                      }
                    </div>
                    <label for="profile_avatar" class="file-label bg-primary">
                      <Icon.Edit2 className='editIcon' />
                    </label>
                    <input onChange={handleImageUpload} type="file" name="profile_avatar" id="profile_avatar" class="file-input"></input>
                  </div>
                  <h5 class="text-center user-name">{userProfile.name}</h5>
                  <h6 class="text-center user-email">{userProfile.email}</h6>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div class="card h-100">
              <div class="card-body">
              <form>
                <div class="row gutters">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 class="mb-2 text-primary">Personal Details</h6>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group mb-2">
                      <label className="form-label" for="fullName">Full Name</label>
                      <input
                        // type="text"
                        // class="form-control"
                        // id="fullName"
                        // value={userProfile.name}
                        // input={(e)=>this.setFullName(e)}


                        type="text"
                        className="form-control"
                        id="fullName"
                        name="name"
                        value={userProfile.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group mb-2">
                      <label className="form-label" for="eMail">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        value={userProfile.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group mb-2">
                      <label className="form-label" for="phone">Phone</label>
                      <InputMask
                        type="text"
                        class="form-control"
                        id="phone"
                        name="phone_number"
                        value={userProfile.phone_number}
                        onChange={handleChange}
                        mask="999-999-9999"
                      />
                    </div>
                  </div>
                  {/* <div class="col-sm-6">
                      <div class="form-group mb-2">
                        <label className="form-label" for="website">Website URL</label>
                        <input
                          type="url"
                          class="form-control"
                          id="website"
                          placeholder="Website url"
                        />
                      </div>
                    </div> */}
                </div>
                {
                  getUserRole != 'admin' ?
                    (
                      <>
                        <div class="row gutters">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <h6 class="mt-3 mb-2 text-primary">Address</h6>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group mb-2">
                              <label className="form-label" for="Street">Location</label>
                              <input
                                type="name"
                                class="form-control"
                                id="Street"
                                name="address"
                                disabled
                                value={userProfile.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group mb-2">
                              <label className="form-label" for="ciTy">Payment ID</label>
                              <input
                                type="name"
                                class="form-control"
                                id="ciTy"
                                // value={ user.stripe_id }
                                disabled
                                value={userProfile.stripe_id}
                              // onChange={alert("user_stripe_id")}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group mb-2">
                              <label className="form-label" for="sTate">Latitude</label>
                              <input
                                type="text"
                                class="form-control"
                                id="sTate"
                                value={userProfile.latitude}
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group mb-2">
                              <label className="form-label" for="zIp">Longitude</label>
                              <input
                                type="text"
                                class="form-control"
                                id="zIp"
                                value={userProfile.longitude}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : ('')
                }
                <div class="text-right mt-2">
                  <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-block" disabled={btnLoading}>
                    {
                      btnLoading ?
                        (
                          <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                        ) : (
                          <>Save Change</>
                        )
                    }
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      )}



      {/* 
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
        <Form.Control
          placeholder="name"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Address</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Stripe Id</InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup> */}
    </>
  );
}

export default Profile;
