import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class PlacesAutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      address: '',
      showErrorMessage: false,
   };
  }

  componentDidMount() {
    this.setState({ address: this.props.address || '' });
  }

  handleChange = address => {
    this.setState({ address });
  };

  onInputHandleAddress = () => {
    var address = "";
    var latitude = "";
    var longitude = "";
    this.props.onSelect(latitude, longitude, address);
  }

  handleSelect = (address, placeId, suggestions) => {
    geocodeByAddress(address, {
      componentRestrictions: { country: 'us' },
      types: ['geocode'],
    })
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        
        // if (suggestions.description == address) {
        //   this.setState({ address });
        //   var latitude = latLng.lat;
        //   var longitude = latLng.lng;
        //   this.props.onSelect(latitude, longitude, address);
        // } else {
        //   this.setState({ address: '' });
        // }
        
        const MIN_ADDRESS_LENGTH = 25; // Minimum length for a valid complete address
        if (address.length >= MIN_ADDRESS_LENGTH) { // Check if the address meets the minimum length requirement
          this.setState({ address, showErrorMessage: false });
          var latitude = latLng.lat;
          var longitude = latLng.lng;
          this.props.onSelect(latitude, longitude, address);
        } else {
          this.setState({ address: '', showErrorMessage: true });
        }

        // const commaCount = address.split(',').length - 1;
        // if (commaCount >= 2) {
        //   // The address contains at least two commas, so it is considered a complete address
        //   this.setState({ address });
        //   var latitude = latLng.lat;
        //   var longitude = latLng.lng;
        //   this.props.onSelect(latitude, longitude, address);
        // } else {
        //   // The address is considered incomplete or short, so clear it
        //   this.setState({ address: '' });
        // }

      })
      .catch(error => console.error('Error', error));
  };

  // handleSelect = (address, placeId, suggestions) => {
  //   geocodeByAddress(address, {
  //     componentRestrictions: { country: 'us' },
  //     types: ['geocode'],
  //   })
  //     .then(results => {
  //       // Check if results exist
  //       if (results && results.length > 0) {
  //         // Get the first result
  //         const firstResult = results[0];
  //         // Get the formatted address
  //         const filteredAddress = firstResult.formatted_address;
  //         // Get the latitude and longitude
  //         const latLng = getLatLng(firstResult);
  //         const latitude = latLng.lat;
  //         const longitude = latLng.lng;
  //         // Trigger the onSelect callback
  //         this.props.onSelect(latitude, longitude, filteredAddress);
  //       }
  //     })
  //     .catch(error => console.error('Error', error));
  // };
  
  render() {
    const { address, showErrorMessage } = this.state;
    const MIN_ADDRESS_LENGTH = 25; // Minimum length for a valid complete address

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: 'us' },
          types: ['geocode'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='locationSearchContainer'>
            <input
              {...getInputProps({
                placeholder: 'Location',
                className: 'form-control',
                autoComplete: 'off',
              })}
              onInput={this.onInputHandleAddress}
            />
            {showErrorMessage && address.length < MIN_ADDRESS_LENGTH && (
              <div className='error-message text-danger' style={{marginTop: '10px'}}>Please enter full address</div>
            )}
            {loading && <div className='loading'>Loading...</div>}
            <ul className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <li
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default PlacesAutocompleteInput;