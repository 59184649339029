// import '../CSS/dealer.css'

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import List from "./List";
import axiosClient from "../axios-client";
import * as Icon from "react-feather"
import { ComponentLoading } from "../Components/ComponentLoading";

// import CountUp from 'react-countup';



const Index = () => {

  const { user, role, notification } = useStateContext();

  const navigate = useNavigate();

  const [dashData, setDashData]= useState([]);
  const [isLoading, setIsLoading]=useState(false)
  const [getRole, setRole]=useState(null)

  const getUser = () => {
    axiosClient.get('/profile')
      .then(({ data }) => {
        setRole(data.role)
      })
      .catch(err => {
      });
  }
  
  const getDashData =()=>{
    axiosClient.post('dashData')
    .then(({ data })=>{
      setDashData(data)
      setIsLoading(true)
      console.log('role=', role)
      if(role == 'dealer'){
        navigate('cars')
      }
    }).catch(error=>{
      console.log('error occurd')
    })
  }

  useEffect(()=>{
    // getUser();
    getDashData();
  },[])

  return (
    <>
      {isLoading ?
        <>{role=='admin' ?
        // <div className='container-fluid'>
        //   <div className="flex">
        //     <div className="row section-0">
        //       <div className="col-xl-3 d-flex justify-content-center mt-2 pt-1">
        //         <div className="icons-1 icons mt-3">
        //           <i className="fa fa-handshake"></i>

        //         </div>
        //         <div className="text">
        //           <h1>
        //           {dashData.dealers ? dashData.dealers : <>0</>}
        //           </h1>
        //           <p>
        //             Active Dealers
        //           </p>
        //         </div>
        //       </div>
        //       <div className="col-xl-3 d-flex justify-content-center mt-2 pt-1">
        //         <div className="icons-2 icons mt-3">
        //           <i className="fa fa-users"></i>
        //         </div>
        //         <div className="text">
        //           <h1>
        //           {dashData.customers ? dashData.customers : <>0</>}
        //           </h1>
        //           <p>
        //             Active Customers
        //           </p>
        //         </div>

        //       </div>
        //       <div className="col-xl-3 d-flex justify-content-center mt-2 pt-1">
        //         <div className="icons-3 icons mt-3">
        //           <i className="fa fa-car"></i>
        //         </div>
        //         <div className="text">
        //           <h1>
        //           {dashData.cars ? dashData.cars : <>0</>}
        //           </h1>
        //           <p>
        //             Active Offers
        //           </p>
        //         </div>
        //       </div>
        //       <div className="col-xl-3 d-flex justify-content-center mt-2 pt-1">
        //         <div className="icons-4 icons mt-3">
        //           <i className="fa-solid fa-cart-shopping"></i>
        //         </div>
        //         <div className="text">
        //           <h1>
        //             {dashData.offers ? dashData.offers : <>0</>}
        //           </h1>
        //           <p>
        //             Active Offers
        //           </p>
        //         </div>

        //       </div>
        //     </div>
        //   </div>
        // </div>
        <>
          <div className="container-fluid mt-4">
            <section id="minimal-statistics">
              {/* first color design */}
              {/* <div className="row">
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorBlueDark">
                            <Icon.Award/>
                          </div>
                          <Icon.Award className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right colorBlueDark">
                            <h1 className="dashCardHeading">
                              {dashData.dealers ?
                              <>{dashData.dealers}</>
                              : <>0</>}
                              </h1>
                            <span className="dashCardSpan">Active Dealers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bg-info">
                            <Icon.Users/>
                          </div>
                          <Icon.Users className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right text-info">
                            <h1 className="dashCardHeading">{dashData.customers ? dashData.customers : <>0</>}</h1>
                            <span className="dashCardSpan">Active Customers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorBlueDark">
                            <Icon.BarChart2/>
                          </div>
                          <Icon.BarChart2 className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right colorBlueDark">
                            <h1 className="dashCardHeading">
                              {dashData.cars ? dashData.cars : <>0</>}
                            </h1>

                            <span className="dashCardSpan">Active Cars</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorPurple">
                            <Icon.CheckCircle/>
                          </div>
                          <Icon.CheckCircle className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right colorPurple">
                            <h1 className="dashCardHeading">
                              {dashData.offers ? dashData.offers : <>0</>}
                            </h1>
                            <span className="dashCardSpan">Active Offers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div> */}

              {/* gradient color */}

              {/* <div className="row">
              <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bg-info">
                            <Icon.Users/>
                          </div>
                          <Icon.Users className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right text-info">
                            <h1 className="dashCardHeading">{dashData.customers ? dashData.customers : <>0</>}</h1>
                            <span className="dashCardSpan">Active Customers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorBlueDark">
                            <Icon.Award/>
                          </div>
                          <Icon.Award className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right colorBlueDark">
                            <h1 className="dashCardHeading">
                              {dashData.dealers ?
                              <>{dashData.dealers}</>
                              : <>0</>}
                              </h1>
                            <span className="dashCardSpan">Active Dealers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorPurple">
                            <Icon.BarChart2/>
                          </div>
                          <Icon.BarChart2 className="bgdashCard colorPurple"/>

                          <div className="media-body text-right colorPurple">
                            <h1 className="dashCardHeading">
                              {dashData.cars ? dashData.cars : <>0</>}
                            </h1>

                            <span className="dashCardSpan">Active Cars</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIcon bgcolorPurpleDark">
                            <Icon.CheckCircle/>
                          </div>
                          <Icon.CheckCircle className="bgdashCard colorBlueDark"/>

                          <div className="media-body text-right colorPurpleDark">
                            <h1 className="dashCardHeading">
                              {dashData.offers ? dashData.offers : <>0</>}
                            </h1>
                            <span className="dashCardSpan">Active Offers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div> */}
              
              {/* uniform color */}

              <div className="row">
                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card dashCard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center dashIcon bgcolorBlueDark">
                              <Icon.Award />
                            </div>
                            <Icon.Award className="bgdashCard colorBlueDark" />

                            <div className="media-body text-right colorBlueDark">
                              <h1 className="dashCardHeading">
                                {dashData.dealers ? (
                                  <>{dashData.dealers}</>
                                ) : (
                                  <>0</>
                                )}
                              </h1>
                              <span className="dashCardSpan">Active Dealers</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card dashCard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center dashIcon bgcolorBlueDark">
                              <Icon.Users />
                            </div>
                            <Icon.Users className="bgdashCard colorBlueDark" />

                            <div className="media-body text-right colorBlueDark">
                              <h1 className="dashCardHeading">
                                {dashData.customers ? dashData.customers : <>0</>}
                              </h1>
                              <span className="dashCardSpan">
                                Active Customers
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card dashCard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center dashIcon bgcolorBlueDark">
                              <Icon.BarChart2 />
                            </div>
                            <Icon.BarChart2 className="bgdashCard colorBlueDark" />

                            <div className="media-body text-right colorBlueDark">
                              <h1 className="dashCardHeading">
                                {dashData.cars ? dashData.cars : <>0</>}
                              </h1>

                              <span className="dashCardSpan">Active Cars</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card dashCard">
                      <div className="card-content">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="align-self-center dashIcon bgcolorBlueDark">
                              <Icon.CheckCircle />
                            </div>
                            <Icon.CheckCircle className="bgdashCard colorBlueDark" />

                            <div className="media-body text-right colorBlueDark">
                              <h1 className="dashCardHeading">
                                {dashData.offers ? dashData.offers : <>0</>}
                              </h1>
                              <span className="dashCardSpan">Active Offers</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>


            </section>
          
          </div>

          {/* <div className="container-fluid mt-4">
            <section id="minimal-statistics">
              <div className="row">
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIconUp bgcolorBlueDark">
                            <Icon.Award/>
                          </div>
                          <div className="media-body text-right colorBlueDark">
                            <h1 className="dashCardHeading">
                              {dashData.dealers ?
                              <>{dashData.dealers}</>
                              : <>0</>}
                              </h1>
                            <span className="bgdashCard">Active Dealers</span>
                            <span className="dashCardSpan">Active Dealers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIconUp bg-info">
                            <Icon.Users/>
                          </div>
                          <div className="media-body text-right text-info">
                            <h1 className="dashCardHeading">{dashData.customers ? dashData.customers : <>0</>}</h1>
                            <span className="bgdashCard">Active Customers</span>
                            <span className="dashCardSpan">Active Customers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIconUp bgcolorBlueDark">
                            <Icon.BarChart2/>
                          </div>
                          <div className="media-body text-right colorBlueDark">
                            <h1 className="dashCardHeading">
                              {dashData.cars ? dashData.cars : <>0</>}
                            </h1>
                            <span className="bgdashCard">Active Cars</span>

                            <span className="dashCardSpan">Active Cars</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12"> 
                  <div className="card dashCard">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="align-self-center dashIconUp bgcolorPurple">
                            <Icon.CheckCircle/>
                          </div>
                          <div className="media-body text-right colorPurple">
                            <h1 className="dashCardHeading">
                              {dashData.offers ? dashData.offers : <>0</>}
                            </h1>
                            <span className="bgdashCard">Active Offers</span>
                            <span className="dashCardSpan">Active Offers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </section>
        
          </div> */}

        </>
        :
        <List/>
        }
        </>
        :
        // <div className="whiteCover">
        //    <div className="d-flex">
        //       <div class="spinner-grow text-secondary" style={{width: "1.5rem", height: "1.5rem"}} role="status">
        //         <span class="visually-hidden">Loading...</span>
        //       </div>

        //       <div class="spinner-grow text-secondary" style={{width: "1.5rem", height: "1.5rem"}} role="status">
        //         <span class="visually-hidden">Loading...</span>
        //       </div>
              
        //    </div>
            
        //     <div>
        //     <p className="mt-1">Loading...</p>
        //     </div>
        // </div>
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>      }
    
    </>
    

  )
}

export default Index