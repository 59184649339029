import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
// import { Tooltip } from "../Components/Tooltip";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
// import 'bootstrap/dist/css/bootstrap.min.css';
//
//token for dealer/me
const accessToken = localStorage.getItem("ACCESS_TOKEN");
//token for admin to fetch all the dealers

const Dealer = (props) => {
  const [getDealers, setDealers] = useState([]);
  const [getComponentLoading, setComponentLoading] = useState(true);

  // Password Modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalClose = () => setShowPasswordModal(false);
  const handlePasswordModalShow = () => setShowPasswordModal(true);

  // Dealer Modal
  const [showDealerModal, setShowDealerModal] = useState(false);
  const handleDealerModalClose = () => setShowDealerModal(false);
  const handleDealerModalShow = () => setShowDealerModal(true);

  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)

  //user id to save password
  const [userId, setUserId] = useState();
  const new_password = useRef(null);
  const confirm_new_password = useRef(null);

  const [getEmail, setEmail] = useState("");

  const getDeals = () => {
    let config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/dealers",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
    .then((response) => {
      const updatedRecords = response.data.map(record => {
        if (record.status === 'inactive') {
          return { ...record, status: 'pending' };
        }
        return record;
      });
      setDealers(updatedRecords);
      setComponentLoading(false);
    })
    .catch((error) => {
      setComponentLoading(false);
    });

  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  className="form-select form-select-sm form-select-solid px-3 m-auto dropDownRemove"
                  // className="form-control d-flex justify-content-center m-auto px-3 dropDownRemove"
                  // value={setGlobalFilter}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                  </option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="disabled">Disable</option>
                  {/* {options.map((option, i) => (
                            <option key={i} value={option}>
                            {option}
                            </option>
                        ))} */}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <div className="customTableSearch d-flex align-items-center position-relative">
                <span className="icon">
                  <Icon.Search />
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control"
                  placeholder="Search"
                  value={value || ""}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="table-sort-asc"></span>
                        ) : (
                          <span className="table-sort-desc"></span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${pageIndex == 0 && rowIndex == 0
                          ? "active"
                          : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                          }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const setPassword = () => {
    setBtnLoading(true)
    // console.log(userId)
    let payload = {
      id: userId,
      new_password: new_password.current.value,
      confirm_new_password: confirm_new_password.current.value,
    }
    axiosClient.post('setPassword', payload)
      .then(() => {
        setBtnLoading(false)
        toast.success("Password Saved", {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        //close model
        handlePasswordModalClose();
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const invitationBtn = () => {
    setBtnLoading(true)
    let payload = {
      email: getEmail
    }
    axiosClient.post('/dealers/invitation', payload)
      .then((res) => {
        toast.success("Invitation Sent", {
          position: "top-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        getDeals()
        handleDealerModalClose() // close dealer model
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updatePassword = async (data) => {
    setShowPasswordModal(true);
    setUserId(data.id);
    // alert(userId);
  };

  const disableBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.post("userStatus", payload).then(() => {
          getDeals();
          toast.success("Disabled successfully", {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const activeUser = async (data) => {
    Swal.fire({
      title: "Are you sure you want to activate?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "active",
        };
        axiosClient.post("userStatus", payload).then(() => {
          getDeals();
          toast.success("Activated successfully", {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "deleted",
        };
        // console.log('data=',data)
        axiosClient.delete("dealers/"+data.id, payload).then(() => {
          getDeals();
          toast.success("Deleted successfully", {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  useEffect(() => {
    getDeals();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const data = row.original;
          var charAt = data.name?data.name.slice(0, 1).toUpperCase():data.email.slice(0, 1).toUpperCase()
          return (
            <>
              <div className="shortAvatar">
                <div className="avatarSm d-flex">

                  <img className={"symbol-circle bg-" + charAt + " img-alt"} src={data.avatar} alt={charAt} />

                </div>
                <strong className="pl-2">{data.name}</strong>
              </div>
              {/* <div className="shortAvatar">
                <div className="avatarSm d-flex">
                  {data.img ?
                    <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100" />
                    :
                    (
                      data.name?
                      (
                        <div className={`userImgDummy userImgDummy-${data.name.slice(0, 1).toUpperCase()}`}>
                          {data.name.slice(0, 1).toUpperCase()}
                        </div>
                      ):<div className={`userImgDummy bgcolorRed`}>+</div>
                    )
                  }
                </div>
                <strong className="pl-2">{data.name}</strong>
              </div> */}
            </>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => {
          const data = row.original;
          return data.email;
        },
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ row }) => {
          const data = row.original;
          return data.address;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ row }) => {
          const data = row.original;
          const formattedDate = data.created_at ? moment(data.created_at).format('MMM D, YYYY h:mm a') : 'N/A';
          return formattedDate
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              {/* {data.status == "active" ? (
                <span className="badge rounded-pill text-bg-success">
                  {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                </span>
              ) : (
                <div>
                  {data.status == "inactive" ? (
                    <span className="badge rounded-pill text-bg-warning">
                      {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                    </span>
                  ) : (
                    <span className="badge rounded-pill text-bg-secondary">
                      {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
                    </span>
                  )}
                </div>
              )} */}
              {/* {data.status=='active' ? */}
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={<Tooltip id="example-tooltip">
                  {data.status == "active" ? <>Click the toggle to disable this user.</>
                    : data.status == "inactive" ? <>This user has not verified their email yet. Click the toggle to manually activate them</> : <>Click the toggle to enable this user.</>}
                </Tooltip>}
              >
                <label class="switch">

                  <input
                    type="checkbox"
                    checked={data.status == "active" ? true : false}
                    onChange={() => {
                      if (data.status == "active") {
                        disableBtn(data);
                      } else {
                        activeUser(data);
                      }
                    }}
                  />
                  <span class={`slider round ${data.status == "inactive" ? 'bgcolorRed' : <></>} `}></span>
                </label>
              </OverlayTrigger>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Set Password</Tooltip>}
                  >
                    <button
                      className="btn btn-primary ml-1 mb-1"
                      // data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        updatePassword(data);
                      }}
                    >
                      <Icon.Key />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Delete</Tooltip>}
                  >
                    <button
                      className="btn btn-danger ml-1 mb-1"
                      // data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        deleteBtn(data);
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </OverlayTrigger>
                {/* <button className="btn btn-primary ml-1 mb-1"><Icon.Edit /></button> */}
                <div className="dropdown userDropdown position-relative">

                  {/* <ul className="dropdown-menu dropdown-menu-end" style={{ boxShadow: 'rgba(0, 0, 0, 0.22) 0px 0px 25px 2px' }}> */}
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{ boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px" }}
                  >
                    <li>
                      <a className="dropdown-item" href="#"
                        onClick={() => {
                          updatePassword(data);
                        }}
                      >
                        <Icon.Key />
                        Update Password
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          activeUser(data);
                        }}
                        href="#"
                      >
                        <Icon.UserCheck />
                        Active User
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          disableBtn(data);
                        }}
                        href="#"
                      >
                        <Icon.UserX />
                        Disable User
                      </a>
                    </li> */}
                  </ul>
                </div>



                {/* :
                                            <label class="switch">
                                                <input type="checkbox"
                                                onChange={() => { activeUser(data) }}
                                                />
                                                <span class="slider round"></span>
                                            </label>

                                        } */}
              </div>
            </>
          );
        },
      },
    ],
    []
  );


  const data = getDealers;

  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Dealers</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dealers
              </li>
            </ol>
          </nav>
        </div>
        <div>
          {/* <Button variant="btn btn-primary mr-2" onClick={handlePasswordModalShow}>
                        <Icon.Plus /> Password
                    </Button> */}
          <Button variant="btn btn-primary" onClick={handleDealerModalShow}>
            <Icon.Plus /> Invite Dealer
          </Button>
        </div>
      </div>

      {/* Set Password Modal */}
      <Modal
        backdrop="static"
        centered
        show={showPasswordModal}
        onHide={handlePasswordModalClose}
      >
        <Modal.Header>
          <Modal.Title><Icon.Key></Icon.Key>Set Password</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handlePasswordModalClose}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* <div className="mb-3">
              <label for="old_password" className="form-label">
                Old Password
              </label>
              <input type="password" className="form-control" id="old_password" required/>
            </div> */}
            
            {/* <div className="mb-3">
              <label for="new_password" className="form-label">
                New Password
              </label>
              <input type="password" className="form-control" ref={new_password} required />
            </div>
            <div className="mb-3">
              <label for="confirm_new_password" className="form-label">
                Confirm Password
              </label>
              <input type="password" className="form-control" ref={confirm_new_password} required />
            </div> */}

            <div className="row">
            <div class="col-xl-12 col-lg-12">
                <div class="form-group mb-3">
                  <label for="new_password">New Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={newPasswordType?("text"):("password")}
                      className="form-control"
                      id="new_password"
                      name="new_password"
                      placeholder='minimum 8 digit password'
                      ref={new_password}
                    />
                    <div className="innerInputIcons">
                      {
                        newPasswordType?
                        (
                          <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12">
                <div class="form-group mb-3">
                  <label for="password_confirmation">Confirm Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={confirmPasswordType?("text"):("password")}
                      class="form-control"
                      id="email_confirmation"
                      name="email_confirmation"
                      placeholder='Must be same as new password'
                      ref={confirm_new_password}
                    />
                    <div className="innerInputIcons">
                      {
                        confirmPasswordType?
                        (
                          <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-primary"
            onClick={handlePasswordModalClose}
          >
            Close
          </Button>
          <button type="submit" className="btn btn-primary" onClick={setPassword} disabled={btnLoading}>
            {
              btnLoading ?
                (
                  <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                ) : (
                  <>Set Password</>
                )
            }
          </button>
        </Modal.Footer>
      </Modal>

      {/* Invite Dealer Modal */}
      <Modal
        backdrop="static"
        centered
        show={showDealerModal}
        onHide={handleDealerModalClose}
      className="hide-btn-close"
      >
        <Modal.Header closeButton>
          <Modal.Title><Icon.Plus></Icon.Plus> Invite a Dealer</Modal.Title>
          <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleDealerModalClose() }}
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label for="email" className="form-label">
                Enter email to send invitation
              </label>
              <input type="email" className="form-control" id="email" required value={getEmail} onChange={e => setEmail(e.target.value)} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-primary"
            onClick={handleDealerModalClose}
          >
            Close
          </Button>
          <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={invitationBtn}>
            {
              btnLoading ?
                (
                  <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                ) : (
                  <>Send Invitation</>
                )
            }
          </button>
        </Modal.Footer>
      </Modal>

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default Dealer;
