import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";

const Signup = () => {

    let { userId, token } = useParams();

    const navigate = useNavigate();

    return (
        <div>

            <div className="container darkMode">
                <div className="centerForm">
                    <div className="authForm box-shadow-1 mb-4">
                        <form>

                            <div className="form-outline mb-3 text-center">
                                <h5 className="mt-1 text-white">
                                    <span style={{lineHeight: '40px', display:'block'}}>Your email has been verified successfully</span>
                                </h5>
                                <p className="text-white">Please open your mobile app and continue login.</p>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;