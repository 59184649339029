import React, { useEffect, useRef, useState, Component } from "react";
import { useStateContext } from "../Context/ContextProvider";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { Link, useParams, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Fancybox from '../Components/Fancybox';
import { Carousel } from '@fancyapps/ui'; // import Carousel component here

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

import plentiCar from '../images/plenti-car.png'
import defaultCarImage from '../images/default-car.PNG'

const List = (props) => {

  let { status } = useParams();
  const navigate = useNavigate();

  const { user, token, role, setUser, setToken, setRole, notification } = useStateContext();

  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getCarDetailsComponentLoading, setCarDetailsComponentLoading] = useState(true);

  // Subscription Modal
  const [sub, setSub] = useState([]);
  const [subscription, setSubscription] = useState(false);

  //list of cars
  const [listCar, setListCar] = useState([]);

  // Subscription Modal
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const handleSubscriptionModalClose = () => setShowSubscriptionModal(false);
  const handleSubscriptionModalShow = () => setShowSubscriptionModal(true);

  const [subscriptionSuccessModal, setSubscriptionSuccessModal] = useState(false);
  const [subscriptionFailedModal, setSubscriptionFailedModal] = useState(false);

  // ShowCarBtn Modal
  const [showShowCarModal, setShowShowCarModal] = useState(false);
  const handleShowCarModalClose = () => setShowShowCarModal(false);
  const handleShowCarModalShow = () => setShowShowCarModal(true);

  // Offer Modal
  const [offer, setOffer] = useState(false);
  //COntrol Offer Model
  const [showOfferModal, setShowOfferModal] = useState(false);
  const handleOfferModalClose = () => setShowOfferModal(false);
  const handleOfferModalShow = () => setShowOfferModal(false);

  const [btnLoading, setBtnLoading] = useState(false)

  //offer Price
  const [offerPrice, setOfferPrice] = useState();
  // const offerPrice = useRef(null);

  // offers Model:
  const [getCarData, setCarData] = useState({});

  const [singleCarData, setSingleCarData] = useState({});
  const [singleCarDetails, setSingleCarDetails] = useState({});
  const [getSingleCarPhotos, setSingleCarPhotos] = useState([])
  const [getSingleCarFeaturedPhoto, setSingleCarFeaturedPhoto] = useState({})
  const [singleCarOffer, setSingleCarOffer] = useState({});

  const [getKey, setKey] = useState(1);

  // const [isOpen, setIsOpen] = useState(false);
  // const [photoIndex, setPhotoIndex] = useState(0);

  // const openLightbox = (index) => {
  //   console.log(index)
  //   setIsOpen(true);
  //   setPhotoIndex(index);
  // };

  // const closeLightbox = () => {
  //   setIsOpen(false);
  // };

  const getCars = () => {
    setComponentLoading(true);
    axiosClient
      .post("/radialcars")
      .then((response) => {
        setListCar(response.data.cars);
        console.log(response);
        setComponentLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setComponentLoading(false);
      });
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="pt-3 pl-3 pr-3 pb-3">
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-customer-table-toolbar="base"
          >
            <div className="customTableSearch d-flex align-items-center position-relative">
              <span className="icon">
                <Icon.Search />
              </span>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control"
                placeholder="Search"
                value={value || ""}
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="table-sort-asc"></span>
                        ) : (
                          <span className="table-sort-desc"></span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      <strong>No data found</strong>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {
          listCar.length > 0 ?
            (
              <div className="pl-3 pr-3">
                <div className="row">
                  <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
                    <div className="dataTables_length" id="kt_customers_table_length">
                      <select
                        name="kt_customers_table_length"
                        className="form-select form-select-sm form-select-solid"
                        value={pageSize}
                        onChange={(e) => {
                          {
                            setPageSize(Number(e.target.value));
                            setLocalStorage(e.target.value);
                          }
                        }}
                        style={{ width: "120px", height: "38px" }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
                    <div id="kt_table_users_paginate">
                      <ul className="pagination mb-0">
                        <li className="page-item" onClick={() => previousPage()}>
                          <a className="page-link prev-angle">
                            <i className="fa fa-chevron-left"></i>
                          </a>
                        </li>
                        {pageOptions.map((pageSize, rowIndex) => {
                          if (currentPageStartIndex <= rowIndex) {
                            currentPageStartIndex = currentPageStartIndex + 1;
                            previousIndexPage = rowIndex - 1;
                          }
                          return previousIndexPage <= currentPageStartIndex ? (
                            <li
                              className={`page-item ${pageIndex == 0 && rowIndex == 0
                                ? "active"
                                : pageIndex + 1 == rowIndex + 1
                                  ? "active"
                                  : ""
                                }`}
                              onClick={() => gotoPage(rowIndex)}
                            >
                              <a className="page-link">{pageSize + 1} </a>
                              {/* {pageIndex + 1} */}
                            </li>
                          ) : null;
                        })}
                        <li className="page-item" onClick={() => nextPage()}>
                          <a className="page-link next-angle">
                            {/* {'Next »'} */}
                            <i className="fa fa-chevron-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // <div className="mt-3 mb-3"></div>
              ''
            )
        }
      </div>
    );
  }

  const subscriptionBtn = () => {
    toast.success("Plan selected successfully", {
      position: "bottom-right",
      autoClose: 1500,
      theme: "colored",
    });
  };

  const deleteBtn = async (data) => {
    // console.log("data=", data);
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.success("Deleted successfully", {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
      }
    });
  };

  const checkout = () => {
    setBtnLoading(false)
    axiosClient.post("/checkout")
      .then(({ data }) => {
        setSub(data);
        console.log(data);
        if (data.customer.subscription) {
          setShowSubscriptionModal(false);
        } else {
          setShowSubscriptionModal(true);
        }
        setSubscription(data.customer.subscription);
        if (data.customer.subscription) {
          getCars();
        }
        setBtnLoading(false)
      })
      .catch(err => {
        setBtnLoading(false)
        console.log('error subscription not found')
      })
  }

  const checkoutReq = () => {
    setBtnLoading(false)
    axiosClient.post("/checkout")
      .then(({ data }) => {
        setSub(data);
        // console.log(data);
        // if (data.customer.subscription) {
        //   setShowSubscriptionModal(false);
        // } else {
        //   setShowSubscriptionModal(true);
        // }
        // setSubscription(data.customer.subscription);
        // if (data.customer.subscription) {
        //   getCars();
        // }
        setBtnLoading(false)
      })
      .catch(err => {
        setBtnLoading(false)
        console.log('error subscription not found')
      })
  }

  useEffect(() => {
    if (status && status == 'success') {
      setSubscriptionSuccessModal(true)
    } else if (status && status == 'failed') {
      setSubscriptionFailedModal(true)
      
      checkoutReq()

    } else {
      checkout()
    }

    window.addEventListener('addressUpdated', (event) => {
      if (event['detail'].value) {
        getCars()
      }
    })

  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        accessor: "avatar",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          var carImage = '';
          if(data.car_photos != undefined && data.car_photos.length > 0) {
            if(data.car_photos[0] != undefined && Object.keys(data.car_photos[0]).length != 0){
              if(data.car_photos[0]['link'] != undefined && data.car_photos[0]['link'] != ''){
                // console.log('data=',data.car_photos[0]['link'])
                carImage = data.car_photos[0]['link']
              }
            }
          }
          return (
            <>
              {/* <div className="carAvatarSm">
                <img src={defaultCarImage} />
              </div> */}
              <div className="avatarSm d-flex">
              {
                data.make?
                (
                  <>
                    <img className={"symbol-circle bg-" + data.make.slice(0, 1).toUpperCase() + " img-alt"} src={carImage} alt={data.make.slice(0, 1).toUpperCase()} />
                  </>
                ):(
                  <img className={"symbol-circle bg-" + "N/A" + " img-alt"} src={carImage} alt={"N/A"} />
                )
              }
              </div>
            </>
          );
        },
      },
      {
        Header: "Make",
        accessor: "make",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              {data.make}
            </>
          );
        },
      },
      {
        Header: "Model",
        accessor: "model",
        Cell: ({ row }) => {
          const data = row.original;
          return data.model ? data.model : 'N/A'
        },
      },

      {
        Header: "Model Year",
        accessor: "model_year",
        Cell: ({ row }) => {
          const data = row.original;
          // console.log('data.details=',JSON.parse(data.details))
          return data.model_year ? data.model_year : 'N/A'
        },
      },
      {
        Header: "VIN",
        accessor: "vin_number",
        Cell: ({ row }) => {
          const data = row.original;
          // console.log('data.details=',JSON.parse(data.details))
          return data.vin_number ? data.vin_number : 'N/A'
        },

      },
      {
        Header: "City",
        accessor: "address",
        Cell: ({ row }) => {
          const data = row.original;
          // console.log('data.details=',JSON.parse(data.details))
          return data.address ? data.address : 'N/A'
        },

      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ row }) => {
          const data = row.original;
          const formattedDate = data.created_at ? moment(data.created_at, 'YYYY-MM-DD').format('MMM D, YYYY') : 'N/A';
          // console.log('data.details=',JSON.parse(data.details))
          return formattedDate

        },
      },
      {
        Header: "Offer Price",
        accessor: "offer_price",
        Cell: ({ row }) => {
          const data = row.original;
          var offerPrice = ''
          if (data.offer) {
            if (data.offer.offer_price) {
              offerPrice = data.offer.offer_price
            } else {
              offerPrice = 'N/A'
            }
          } else {
            offerPrice = 'N/A'
          }
          return (
            <>
            {
              offerPrice!='N/A'?
              (
                <div className="d-flex align-items-center">
                  <strong className="mr-1">{offerPrice}</strong>
                  <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={
                          <Tooltip id="example-tooltip">You already offered to this car <strong>{data.make}</strong></Tooltip>
                        }
                    >
                      <Icon.Info style={{width:'18px'}} />
                    </OverlayTrigger>
                </div>
              ):(offerPrice)
            }
            </>
          )
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ row }) => {
      //     const data = row.original;
      //     return (
      //       <>
      //         <span className="badge rounded-pill text-bg-primary">Active</span>
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                <button
                  key={row.id}
                  className="btn btn-primary ml-1 mb-1 mr-1 mt-1"
                  onClick={() => {
                    ShowCarBtn(data);
                  }}
                >
                  <Icon.ZoomIn />
                </button>
                {/* <button
                  key={row.id}
                  className="btn btn-success ml-1 mb-1"
                  onClick={() => {
                    MakeOffer(data);
                  }}
                >
                  <Icon.CreditCard />
                </button> */}
                {/* <button
                  className="btn btn-danger ml-1 mb-1"
                  onClick={() => {
                    deleteBtn(data);
                  }}
                >
                  <Icon.Trash />
                </button> */}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const { msg } = useParams();

  if (msg) {
    toast.error("Payment not Successful", {
      position: "bottom-right",
      autoClose: 2500,
      theme: "colored",
    });
  }

  // offers function
  const MakeOffer = (data) => {
    // setCarData(data)
    setShowOfferModal(true)
    // setShowShowCarModal(false)

  }

  //show cars to model
  const ShowCarBtn = (data) => {
    setShowOfferModal(false)
    getCarDetails(data.id)
    setCarData(data)
    // var details = data.details ? JSON.parse(data.details) : {}
    // setCarDetails(details)
    // console.log('data=', data)
    setShowShowCarModal(true)
  }

  //submit offer to backend
  const SubmitOfferBtn = () => {
    setBtnLoading(true);
    // if (offerPrice.current.value) {
    const payload = {
      car_id: getCarData.id,
      customer_id: getCarData.user_id,
      offer_price: offerPrice,
    }
    axiosClient.post("/make-offer", payload)
      .then((response) => {
        getCars()
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false);
        setShowOfferModal(false);
        setShowShowCarModal(false);
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });




    // } else {
    //   setShowOfferModal(false)
    //   setShowShowCarModal(false)

    // }
    // else{

    //   toast.error("Please Enter a Valid Amount", {
    //     position: "bottom-right",
    //     autoClose: 1500,
    //     theme: 'colored'
    // });
    // }
    //toast and axios client to hit end point
  }

  const checkImageExists = (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = imageUrl;
    });
  }

  //get single car details
  const getCarDetails = (carId) => {
    setBtnLoading(true);
    setCarDetailsComponentLoading(true)
    axiosClient.get("/dealers/car/" + carId)
      .then((response) => {
        setCarDetailsComponentLoading(false)
        setBtnLoading(false)
        var resData = response.data.car_details
        setSingleCarData(resData);
        setSingleCarDetails(resData.details)

        setKey(getKey+1)
        // var photos = []
        // var showNumberOfPhotos = 5
        // var hideNumberOfPhotos = resData.car_photos-5
        // resData.car_photos.forEach((element,index) => {
        //   // checkImageExists(element.link)
        //   // .then((imageExists) => {
        //   //   if (imageExists) {
        //   //     // Do something with the valid image URL
        //   //     photos.push({src:element.link})
        //   //   }
        //   // });
        //   console.log(index)
        //   var indexVal = index+1
        //   var isFeatured = indexVal == 1?'featured':'';
        //   var column = ''
        //   if(indexVal == 1){
        //     column = ''
        //   } else if (indexVal <= showNumberOfPhotos){
        //     column = 'galleryColumns'
        //   } else {
        //     column = 'galleryOthers'
        //   }
        //   // var column = indexVal <= showNumberOfPhotos?'galleryColumns':'galleryOthers';
        //   photos.push({
        //     src: element.link,
        //     isFeatured: isFeatured,
        //     column: column
        //   })

        // });
        // // console.log('photos=',photos)
        // setSingleCarPhotos({gallery:photos,otherPhotos: hideNumberOfPhotos})
        const [firstPhoto, ...restPhotos] = resData.car_photos;
        setSingleCarFeaturedPhoto(firstPhoto)
        setSingleCarPhotos(restPhotos)
        setSingleCarOffer(resData.offer)
        setKey(getKey+1)

        var price = resData.offer ? resData.offer.offer_price : 0
        setOfferPrice(price)
      })
      .catch(err => {
        setCarDetailsComponentLoading(false)
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }

  const data = listCar;
  // const singleData= getCarData;

  const subscriptionCloseButton = () => {
    Swal.fire({
      title: "Are you sure want to close?",
      text: "You are going to logout If you click on close.",
      icon: "question",
      confirmButtonColor: '#f32013',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.post('/logout')
          .then(() => {
            setUser({})
            setRole(null)
            setToken(null)
            handleSubscriptionModalClose()
          })
      }
    })
  }

  const setCloseSuccessModal = () => {
    navigate("/cars");
    checkout()
    setSubscriptionSuccessModal(false)
  }

  const setCloseFailedModal = () => {
    // navigate("/cars");
    // checkout()
    // setSubscriptionFailedModal(false)
    // setShowSubscriptionModal(true)
    
    Swal.fire({
      title: "Are you sure want to close?",
      text: "You are going to logout If you click on close.",
      icon: "question",
      confirmButtonColor: '#f32013',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Close',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.post('/logout')
          .then(() => {
            setUser({})
            setRole(null)
            setToken(null)
            setSubscriptionFailedModal(false)
          })
      }
    })

  }

  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Cars for Sale</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cars for Sale
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <div className="d-flex flex-column flex">
        <p>Valid User:-</p>
        <p className="d-flex">
          {sub ? <p>{sub.success}</p> : <p>ACTIVE USER</p>}
        </p>
        <a className="btn w-25" href={sub.link}>
          {sub.link ? <p>Go Subscribe</p> : <p>Good To Go</p>}
        </a>
      </div> */}

      {/* Subscription Modal */}
      <Modal
        backdrop="static"
        centered
        show={showSubscriptionModal}
        onHide={handleSubscriptionModalClose}
        className="modal-md subscriptionModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscription Required!</Modal.Title>
          <button
            type="button"
            className="btnClose custom"
            onClick={() => { subscriptionCloseButton() }}
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="bblr bbrr">
          <div className="priceWithImage">
            <div className="subscriptionPrice">
              <span className="text">
                <span>Monthly</span>
                <span>Fee</span>
              </span>
              <span className="price">10$</span>
            </div>
            {/* <div className="subscriptionCarImage">
              <img src={plentiCar} />
            </div> */}
          </div>
          <div className="text-center">
            <p>Get unlimited access to the cars, being sold around you.</p>
            <a href={sub.link} className="btn btn-primary btn-block" disabled={btnLoading}>
              {
                btnLoading ?
                  (
                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                  ) : (
                    <>Go to Payment</>
                  )
              }
            </a>
            {/* <a href={sub.link}>
              Go to Payment
            </a> */}
          </div>
          <br />
        </Modal.Body>
      </Modal>

      {/* <Modal
        backdrop="static"
        centered
        show={showSubscriptionModal}
        onHide={handleSubscriptionModalClose}
        className="modal-md"
      >
        <Modal.Body>
          <button
            type="button"
            className="btnClose custom"
            onClick={() => { subscriptionCloseButton() }}
          >
            <i className="fa fa-close"></i>
          </button>
          <div className="planA">
            <h1 className="name text-center">Subscription Required!</h1>
            <div className="shortDescription text-center">
              <p>
                Get unlimited access to the cars, being sold around you.
              </p>
            </div>
            <div className="price text-center">
              <span className="sign">$</span>
              <span className="amount">10</span>
            </div>
            <div className="timePeriod text-center">Monthly</div>
            <div className="text-center">
              <a href={sub.link}>
                <button className="btn btn-primary" onClick={subscriptionBtn}>
                  Go to Payment
                </button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* Car Show Modal */}
      <Modal
        backdrop="static"
        centered
        show={showShowCarModal}
        onHide={handleShowCarModalClose}
        className="modal-xl"
      >
        <Modal.Body>
          <button
            type="button"
            className="btnClose custom"
            onClick={handleShowCarModalClose}
          >
            <i className="fa fa-close"></i>
          </button>
          {getCarDetailsComponentLoading ? (
            <div className="card loadingCard">
              <div className="card-body">
                <ComponentLoading></ComponentLoading>
              </div>
            </div>
          ) : (
            <div class="container">
              <div class="row gutters">
                <div class="col-sm-4">
                  <div class="account-settings">

                      <div className={"fancyBoxGallery"}>
                        <Fancybox
                          options={{
                            Carousel: {
                              infinite: false,
                            },
                          }}
                        >
                          <div>
                            {
                              getSingleCarPhotos.length > 0 || getSingleCarFeaturedPhoto && Object.keys(getSingleCarFeaturedPhoto).length > 0 ?
                              (
                                <>
                                  {/* {JSON.stringify(getSingleCarFeaturedPhoto)} */}
                                  {
                                    getSingleCarFeaturedPhoto && Object.keys(getSingleCarFeaturedPhoto).length > 0?
                                    (
                                      <a className={"featuredImage"} data-fancybox="gallery" href={getSingleCarFeaturedPhoto.link}>
                                        <img
                                          alt={getSingleCarFeaturedPhoto.name}
                                          src={getSingleCarFeaturedPhoto.link}
                                        />
                                      </a>
                                    ):('')
                                  }
                                  <div className="galleryScroll">
                                    {getSingleCarPhotos.map((image, index) => (
                                      <>
                                        <a className={"galleryItem"} data-fancybox="gallery" href={image.link}>
                                          <img
                                            alt={image.name}
                                            src={image.link}
                                          />
                                        </a>
                                      </>
                                    ))}
                                  </div>
                                </>
                              ):(
                                <a className={"featuredImage"} data-fancybox="gallery" href={defaultCarImage}>
                                  <img
                                    alt={defaultCarImage}
                                    src={defaultCarImage}
                                  />
                                </a>
                              )
                            }
                          </div>
                        </Fancybox>
                      </div>

                      <div class="carPhotosGallery" key={getKey}>
                        {/* {getSingleCarPhotos.gallery.map((image, index) => (
                          <>
                            <SideBySideMagnifier
                              className="carPhoto"
                              imageSrc={image.src}
                              imageAlt="Example"
                              largeImageSrc={image.src} // Optional
                              autoInPlace={false}
                              fillAvailableSpace={false}
                              magnifierSize="100%"
                              previewSizePercentage="100"
                              size="300"
                              overlayBoxImageSize="300"
                              magnifierBorderSize="10"
                            />
                          </>
                        ))} */}
                      </div>
                      <div class="row gutters" style={{clear: "both"}}>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                          <div class="col text-center">
                            {!showOfferModal ?
                              (
                                Object.keys(singleCarOffer).length != 0 ?
                                  (
                                    <>
                                      <div className="mb-3">
                                        <strong>
                                          {singleCarOffer.offer_price ? singleCarOffer.offer_price : ''}
                                        </strong>
                                      </div>

                                      {
                                        singleCarData.offer?
                                        (
                                          singleCarData.offer.status == 'pending'?
                                          (
                                            <button
                                              type="button"
                                              id="submit"
                                              name="submit"
                                              class="btn btn-primary"
                                              onClick={MakeOffer}
                                            >
                                              Change Offer
                                            </button>
                                          ):(
                                            singleCarData.offer.status == 'accepted'?
                                            (
                                              <div class="alert alert-success" role="alert">Your offer has been accepted by car customer now you can not change offer price any more.</div>
                                            ):(
                                              <div class="alert alert-danger" role="alert">Your offer has been rejected by car customer now you can not change offer price any more.</div>
                                            )
                                          )
                                        ):('')
                                      }
                                    </>
                                  ) : (
                                    <button
                                      type="button"
                                      id="submit"
                                      name="submit"
                                      class="btn btn-primary"
                                      onClick={MakeOffer}
                                    >
                                      Make an Offer
                                    </button>
                                  )
                              ) : ('')
                            }
                            {showOfferModal &&
                              <div>
                              <form>
                                <div class="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="offerPrice"
                                    value={offerPrice}
                                    // ref={offerPrice}
                                    onChange={e => setOfferPrice(e.target.value)}
                                  />
                                </div>
                                {
                                  Object.keys(singleCarOffer).length != 0 ?
                                    (
                                      <button type="submit" className="btn btn-primary btn-block mt-2" onClick={SubmitOfferBtn} disabled={btnLoading}>
                                        {
                                          btnLoading ?
                                            (
                                              <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                              <>Update Offer</>
                                            )
                                        }
                                      </button>
                                    ) : (
                                      <button type="button" className="btn btn-primary btn-block mt-2" onClick={SubmitOfferBtn} disabled={btnLoading}>
                                        {
                                          btnLoading ?
                                            (
                                              <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                              <>Submit Offer</>
                                            )
                                        }
                                      </button>
                                    )
                                }
                              </form>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-2 text-primary">Vehicle Details</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="make">Make: </label>
                        <div className="in">
                          <input type="text" className="form-control-plaintext text-bold-700" id="make" value={singleCarData.make} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="model">Model: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="model" value={singleCarData.model} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="model_year">Model Year: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="model_year" value={singleCarData.model_year} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="Manufacturer">Manufacturer: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="Manufacturer" name="Manufacturer" value={singleCarDetails.Manufacturer ? singleCarDetails.Manufacturer : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="VehicleType">Vechile Type: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="VehicleType" value={singleCarDetails.VehicleType ? singleCarDetails.VehicleType : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="Seats">Total Seats: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="Seats" value={singleCarDetails.Seats ? singleCarDetails.Seats : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="PlantCountry">Plant Country: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="PlantCountry" value={singleCarDetails.PlantCountry ? singleCarDetails.PlantCountry : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="v">Body Class: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="BodyClass" value={singleCarDetails.BodyClass ? singleCarDetails.BodyClass : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="TransmissionStyle">Transmission Style: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="TransmissionStyle" value={singleCarDetails.TransmissionStyle ? singleCarDetails.TransmissionStyle : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="DriveType">Drive Type: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="DriveType" value={singleCarDetails.DriveType ? singleCarDetails.DriveType : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="TopSpeedMPH">Top Speed MPH: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="TopSpeedMPH" value={singleCarDetails.TopSpeedMPH ? singleCarDetails.TopSpeedMPH : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Engine</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="EngineModel">Engine Model: </label>
                        <div className="in">
                          <input type="name" class="form-control-plaintext text-bold-700" id="EngineModel" value={singleCarDetails.EngineModel ? singleCarDetails.EngineModel : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="engine_power">Engine Power: </label>
                        <div className="in">
                          <input type="name" class="form-control-plaintext text-bold-700" id="engine_power" value={''} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Location</h6>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="engine_power">Location: </label>
                        <div className="in">
                          <input type="text" className="form-control-plaintext text-bold-700" id="fullName" value={singleCarData.location} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Offer Modal */}
      <Modal
        backdrop="static"
        centered
        // show={showOfferModal}
        onHide={handleOfferModalClose}
        className="modal-l"
      >
        <Modal.Body>
          <button
            type="button"
            className="btnClose custom"
          // onClick={handleOfferModalClose}
          >
            <i className="fa fa-close"></i>
          </button>
          <div class="container">
            <div class="row gutters text-align d-flex flex-column">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="account-settings">
                      <div class="user-profile">
                        <div class="user-avatar img-fluid text-center">
                          <img
                            src="https://www.topgear.com/sites/default/files/news/carousel/2015/04/Large%20Image_10548.jpg?w=976&h=549"
                            alt="Maxwell Admin"
                            className="img-fluid"
                          />
                        </div>
                        {/* <h5 class="user-name">{ userProfile.name }</h5> */}
                        {/* <h6 class="user-email">{ userProfile.email }</h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="row gutters">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 class="text-center mb-2 text-primary">Make Your Offer to Buy This car</h6>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                          <input
                            type="number"
                            className="form-control"
                            id="fullName"
                            name="offerPrice"
                            ref={offerPrice}
                          />
                        </div>
                      </div>

                      <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                          <div class="col text-center">

                            <button
                              type="button"
                              id="submit"
                              name="submit"
                              class="btn btn-primary"
                              onClick={SubmitOfferBtn}
                            >
                              Make an Offer
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Subscription SuccessModal */}
      <Modal
        backdrop="static"
        centered
        show={subscriptionSuccessModal}
        onHide={handleSubscriptionModalClose}
        className="modal-md subscriptionModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscription Activated!</Modal.Title>
          <button
            type="button"
            className="btnClose custom"
            onClick={() => { setCloseSuccessModal() }}
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="bblr bbrr">
          <div className="priceWithImage">
            <div className="subscriptionPrice">
              <span className="text">
                <span>Monthly</span>
                <span>Fee</span>
              </span>
              <span className="price">10$</span>
            </div>
            <div className="subscriptionCarImage">
              <img src={plentiCar} />
            </div>
          </div>
          <div className="text-center">
            <p>Now you can access the service for one Month</p>
            <Button variant="primary" onClick={setCloseSuccessModal}>View Cars</Button>
          </div>
          <br />
        </Modal.Body>
      </Modal>

      {/* Subscription FailedModal */}
      <Modal
        backdrop="static"
        centered
        show={subscriptionFailedModal}
        onHide={handleSubscriptionModalClose}
        className="modal-md subscriptionModal"
      >
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>Subscription Failed!</Modal.Title>
          <button
            type="button"
            className="btnClose bg-warning"
            onClick={() => { setCloseFailedModal() }}
          >
            <i className="fa fa-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="bblr bbrr">
          <div className="priceWithImage">
            <div className="subscriptionPrice">
              <span className="text">
                <span>Monthly</span>
                <span>Fee</span>
              </span>
              <span className="price">10$</span>
            </div>
            <div className="subscriptionCarImage">
              <img src={plentiCar} />
            </div>
          </div>
          <div className="text-center">
            <p>Please pay monthly subscription Fee to continue</p>
            {/* <Button variant="primary" onClick={setCloseFailedModal}>Go To Payment</Button> */}
            <a href={sub.link} className="btn btn-primary btn-block" disabled={btnLoading}>
              {
                btnLoading ?
                  (
                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                  ) : (
                    <>Go to Payment</>
                  )
              }
            </a>
          </div>
          <br />
        </Modal.Body>
      </Modal>

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        // <a href={sub.link}> Please Subscribe</a>
        <div className="card">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default List;
