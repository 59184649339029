import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

import Fancybox from '../Components/Fancybox';
import { Carousel } from '@fancyapps/ui'; // import Carousel component here

import moment from "moment";
import defaultCarImage from '../images/default-car.PNG'

//token for dealer/me
const accessToken = localStorage.getItem("ACCESS_TOKEN");
//token for admin to fetch all the dealers

const AdminCars = (props) => {
  const [getDealers, setDealers] = useState([]);
  const [getComponentLoading, setComponentLoading] = useState(true);

  // Password Modal
  const [showOffersModel, setShowOffersModal] = useState(false);
  const handleOffersModalClose = () => setShowOffersModal(false);
  const handleOffersModalShow = () => setShowOffersModal(true);

  // Dealer Modal
  const [showDealerModal, setShowDealerModal] = useState(false);
  const handleDealerModalClose = () => setShowDealerModal(false);
  const handleDealerModalShow = () => setShowDealerModal(true);

  //user id to save password
  const [userId, setUserId] = useState();
  const new_password = useRef(null);
  const confirm_new_password = useRef(null);

  //single offer show
  const [offer, setOffer] = useState("");
  const [getOffer, setGetOffer] = useState([]);
  //modal table sorting
  const [isSortOrderReversed, setIsSortOrderReversed] = useState(false);

  


  const getDeals = () => {
    let config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/adminCars",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDealers(response.data);
        setComponentLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setComponentLoading(false);
      });
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  // className="form-control"
                  className="form-select form-select-sm m-auto dropDownRemove"
                  // value={setGlobalFilter}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                    <Icon.ChevronDown />
                  </option>
                  <option value="active">Active</option>
                  <option value="sold">Sold</option>
                  <option value="disabled">Disabled</option>
                  {/* {options.map((option, i) => (
                            <option key={i} value={option}>
                            {option}
                            </option>
                        ))} */}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <div className="customTableSearch d-flex align-items-center position-relative">
                <span className="icon">
                  <Icon.Search />
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control"
                  placeholder="Search"
                  value={value || ""}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="table-sort-asc"></span>
                        ) : (
                          <span className="table-sort-desc"></span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={10}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${
                          pageIndex == 0 && rowIndex == 0
                            ? "active"
                            : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                        }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const setPassword = () => {
    console.log(userId);
    let payload = {
      id: userId,
      new_password: new_password.current.value,
      confirm_new_password: confirm_new_password.current.value,
    };
    axiosClient
      .post("setPassword", payload)
      .then(() => {
        toast.success("Password Saved", {
          position: "top-right",
          autoClose: 1500,
          theme: "colored",
        });
        //close model
        handleOffersModalClose();
      })
      .catch((err) => {
        var firstErrorMessage = "";
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors;
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  };

  const saveBtn = () => {
    toast.success("Saved successfully", {
      position: "bottom-right",
      autoClose: 1500,
      theme: "colored",
    });
  };

  const updatePassword = async (data) => {
    setShowOffersModal(true);
    setUserId(data.id);
    // alert(userId);
  };

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.post("userStatus", payload).then(() => {
          getDeals();
          toast.success("Disabled successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const singleOffer = async(data) =>{
    setOffer(data);

    if(data.car_offers_count!==0){  
      let payload ={
        'car_id' : data.id
      }
      axiosClient.post('carsOffers',payload)
      .then(( response )=>{
        // setGetOffer([...response.data.offer_price].sort((a, b) =>  a[response.data.offer_price] - b[response.data.offer_price]))

        const sortedData = response.data.sort((a, b) => b.offer_price - a.offer_price);
        setGetOffer(sortedData);
        handleOffersModalShow();
        
        // setGetOffer(response.data);
        // handleOffersModalShow();

      })
      .catch( error=>{
        console.log('getOffer error',getOffer)
      });
      
    }
    else{
      handleOffersModalShow();
    }

  }

  const handleSortOrder = () => {
    setIsSortOrderReversed(!isSortOrderReversed);
    setGetOffer(prevState => {
      return [...prevState].sort((a, b) => {
        if (isSortOrderReversed) {
          return b.offer_price - a.offer_price;
        } else {
          return a.offer_price - b.offer_price;
        }
      });
    });
  };
  
  

  const disableBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.post("car/"+data.id+"/status", payload).then(() => {
          getDeals();
          toast.success("Disabled successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const activeUser = async (data) => {
    Swal.fire({
      title: "Are you sure you want to activate?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "active",
        };
        axiosClient.post("car/"+data.id+"/status", payload).then(() => {
          getDeals();
          toast.success("Activated successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  

  

  const [btnLoading, setBtnLoading] = useState(false)
  const [getCarDetailsComponentLoading, setCarDetailsComponentLoading] = useState(true);
  
  // ShowCarBtn Modal
  const [showShowCarModal, setShowShowCarModal] = useState(false);
  const handleShowCarModalClose = () => setShowShowCarModal(false);
  const handleShowCarModalShow = () => setShowShowCarModal(true);
  
  const [showOfferModal, setShowOfferModal] = useState(false);
  const handleOfferModalClose = () => setShowOfferModal(false);
  const handleOfferModalShow = () => setShowOfferModal(false);

  const [getCarData, setCarData] = useState({});

  const [singleCarData, setSingleCarData] = useState({});
  const [singleCarDetails, setSingleCarDetails] = useState({});
  const [getSingleCarPhotos, setSingleCarPhotos] = useState([])
  const [getSingleCarFeaturedPhoto, setSingleCarFeaturedPhoto] = useState({})
  const [singleCarOffer, setSingleCarOffer] = useState({});
  
  const [offerPrice, setOfferPrice] = useState();
  
  const [getKey, setKey] = useState(1);

  //get single car details
  const getCarDetails = (carId) => {
    setBtnLoading(true);
    setCarDetailsComponentLoading(true)
    axiosClient.get("/dealers/car/" + carId)
      .then((response) => {
        setCarDetailsComponentLoading(false)
        setBtnLoading(false)
        var resData = response.data.car_details
        setSingleCarData(resData);
        setSingleCarDetails(resData.details)

        setKey(getKey+1)
        const [firstPhoto, ...restPhotos] = resData.car_photos;
        setSingleCarFeaturedPhoto(firstPhoto)
        setSingleCarPhotos(restPhotos)
        setSingleCarOffer(resData.offer)
        setKey(getKey+1)

        var price = resData.offer ? resData.offer.offer_price : 0
        setOfferPrice(price)
      })
      .catch(err => {
        setCarDetailsComponentLoading(false)
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }

  //show cars to model
  const ShowCarBtn = (data) => {
    getCarDetails(data.id)
    setCarData(data)
    setShowShowCarModal(true)
  }

  // offers function
  const MakeOffer = (data) => {
    // setCarData(data)
    setShowOfferModal(true)
    // setShowShowCarModal(false)

  }

  //submit offer to backend
  const SubmitOfferBtn = () => {
    setBtnLoading(true);
    // if (offerPrice.current.value) {
    const payload = {
      car_id: getCarData.id,
      customer_id: getCarData.user_id,
      offer_price: offerPrice,
    }
    axiosClient.post("/make-offer", payload)
      .then((response) => {
        getDeals()
        toast.success(response.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false);
        setShowOfferModal(false);
        setShowShowCarModal(false);
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });
  }



  useEffect(() => {
    getDeals();
  }, []);

  const columns = React.useMemo(
    () => [
      
      {
        Header: "Image",
        accessor: "avatar",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          var carImage = '';
          if(data.car_photos != undefined && data.car_photos.length > 0) {
            if(data.car_photos[0] != undefined && Object.keys(data.car_photos[0]).length != 0){
              if(data.car_photos[0]['link'] != undefined && data.car_photos[0]['link'] != ''){
                // console.log('data=',data.car_photos[0]['link'])
                carImage = data.car_photos[0]['link']
              }
            }
          }
          return (
            <>
              {/* <div className="carAvatarSm">
                <img src={defaultCarImage} />
              </div> */}
              <div className="avatarSm d-flex">
              {
                data.make?
                (
                  <>
                    <img className={"symbol-circle bg-" + data.make.slice(0, 1).toUpperCase() + " img-alt"} src={carImage} alt={data.make.slice(0, 1).toUpperCase()} />
                  </>
                ):(
                  <img className={"symbol-circle bg-" + "N/A" + " img-alt"} src={carImage} alt={"N/A"} />
                )
              }
              </div>
            </>
          );
        },
      },
      {
        Header: "VIN",
        accessor: "VIN",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="shortAvatar d-flex">
                {/* <div className="avatarSm">
                  <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100" />
                </div> */}
                <strong className="pl-2">{data.vin_number}</strong>
              </div>
            </>
          );
        },
      },
      {
        Header: "Model",
        accessor: "model",
        Cell: ({ row }) => {
          const data = row.original;
          return data.model;
        },
      },
      {
        Header: "Make",
        accessor: "make",
        Cell: ({ row }) => {
          const data = row.original;
          return data.make;
        },
      },
      {
        Header: "Year",
        accessor: "year",
        Cell: ({ row }) => {
          const data = row.original;
          return data.model_year;
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ row }) => {
          const data = row.original;
          return moment(data.created_at).format("MMMM DD , YYYY");
        },
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ row }) => {
          const data = row.original;
          return data.address;
        },
      },
      {
        Header: "Offers",
        accessor: "car_offers_count",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <div className="badge rounded-pill text-bg-info cursor-pointer"
            onClick={()=>singleOffer(data)}>
              {data.car_offers_count}
            </div>
          );
          // return data.vehile_type;
        },
      },

      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: ({ row }) => {
      //     const data = row.original;
      //     return (
      //       <>
      //         {data.status == "active" ? (
      //           <span className="badge rounded-pill text-bg-success">
      //             {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
      //           </span>
      //         ) : (
      //           <div>
      //             {data.status == "inactive" ? (
      //               <span className="badge rounded-pill text-bg-warning">
      //                 {/* {data.status} */}
      //                 {data.status.charAt(0).toUpperCase() +
      //                   data.status.slice(1)}
      //               </span>
      //             ) : (
      //               <span className="badge rounded-pill text-bg-secondary">
      //                 {data.status.charAt(0).toUpperCase() +
      //                   data.status.slice(1)}
      //               </span>
      //             )}
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      // },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">
                      {data.status== "active" ? <>Click the toggle to disable this car.</>
                      :data.status== "inactive" ?<>This car has not verified their email yet. Click the toggle to manually activate them</> :<>Click the toggle to enable this user.</>}
                      </Tooltip>}
                >
                <label class="switch">
               
                  <input
                    type="checkbox"
                    checked={data.status == "active" ? true : false}
                    onChange={() => {
                      if (data.status == "active") {
                        disableBtn(data);
                      } else {
                        activeUser(data);
                      }
                    }}
                  />
                  <span class={`slider round ${data.status == "inactive" ? 'bgcolorRed':<></>} `}></span>
                </label>
                </OverlayTrigger>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                <button
                  key={row.id}
                  className="btn btn-primary ml-1 mb-1 mr-1 mt-1"
                  onClick={() => {
                    ShowCarBtn(data);
                  }}
                >
                  <Icon.ZoomIn />
                </button>
              </div>
            </>
          );
        },
      },

    ],
    []
  );

  const data = getDealers;

  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">All Cars</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                All Cars
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Set Offers Modal */}
      <Modal
        backdrop="static"
        centered
        show={showOffersModel}
        onHide={handleOffersModalClose}
        className="modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            {/* <Icon.Package/>  */}
            Offers against {offer.vin_number}
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleOffersModalClose}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          {offer.car_offers_count ?
                  <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.</th>
                      <th scope="col">Dealer</th>
                      <th scope="col" onClick={handleSortOrder} className="cursor-pointer">Total Offer {isSortOrderReversed ?<Icon.ArrowDown className="sortingicon"/>: <Icon.ArrowUp className="sortingicon"/>}</th>
                      <th scope="col">Offer Date</th>
                      <th scope="col">Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {getOffer.map((caroffer,index)=> (
                      <tr key={index}>
                        <td scope="col">{index + 1}</td>
                        <td scope="col">{caroffer.dealer.name}</td>
                        <td scope="col" onClick={handleSortOrder}>{caroffer.offer_price}</td>
                        <td scope="col">
                          {moment.utc(caroffer.created_at).format("MMMM DD, YYYY, hh:mm A UTC")} {/* UTC TIME ZONE*/}
                        </td>
                        <td scope="col" className="text-center">
                          {
                            caroffer.status == 'pending'?
                            (
                              <>
                                <span className="badge rounded-pill text-bg-secondary text-capitalize">
                                  In-Review
                                </span>
                              </>
                            ):caroffer.status == 'rejected'?(
                              <>
                                <span className="badge rounded-pill text-bg-danger text-capitalize">
                                  {caroffer.status}
                                </span>
                              </>
                            ):(
                              <>
                                <span className="badge rounded-pill text-bg-success text-capitalize">
                                  {caroffer.status}
                                </span>
                              </> 
                            )
                          }
                        </td>

                     </tr>
                    ))}
    
                  </tbody>
                </table>
                :
                <h1>This Car Had Not Received any Offer Yet</h1>

          }

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-primary"
            onClick={handleOffersModalClose}
          >
            Close
          </Button>
          {/* <Button variant="btn btn-primary" onClick={setPassword}>
            Set Password
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* Invite Dealer Modal */}
      <Modal
        backdrop="static"
        centered
        show={showDealerModal}
        onHide={handleDealerModalClose}
        // className="modal-sm"
      >
        <Modal.Header>
          <Modal.Title>
            <Icon.Plus></Icon.Plus>Add Dealer
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleDealerModalClose}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* <div className="mb-3">
              <label for="old_password" className="form-label">
                Old Password
              </label>
              <input type="password" className="form-control" id="old_password" required/>
            </div> */}
            <div className="mb-3">
              <label for="email" className="form-label">
                add email to send invitation
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                required
              />
            </div>
            {/* <div className="mb-3">
              <label for="confrim_password" className="form-label">
                Confirm Password
              </label>
              <input type="password" className="form-control" id="confrim_password" required/>
            </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-primary"
            onClick={handleDealerModalClose}
          >
            Close
          </Button>
          <Button variant="btn btn-primary" onClick={saveBtn}>
            Invite
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Car Show Modal */}
      <Modal
        backdrop="static"
        centered
        show={showShowCarModal}
        onHide={handleShowCarModalClose}
        className="modal-xl"
      >
        <Modal.Body>
          <button
            type="button"
            className="btnClose custom"
            onClick={handleShowCarModalClose}
          >
            <i className="fa fa-close"></i>
          </button>
          {getCarDetailsComponentLoading ? (
            <div className="card loadingCard">
              <div className="card-body">
                <ComponentLoading></ComponentLoading>
              </div>
            </div>
          ) : (
            <div class="container">
              <div class="row gutters">
                <div class="col-sm-4">
                  <div class="account-settings">

                      <div className={"fancyBoxGallery"}>
                        <Fancybox
                          options={{
                            Carousel: {
                              infinite: false,
                            },
                          }}
                        >
                          <div>
                            {
                              getSingleCarPhotos.length > 0 || getSingleCarFeaturedPhoto && Object.keys(getSingleCarFeaturedPhoto).length > 0 ?
                              (
                                <>
                                  {/* {JSON.stringify(getSingleCarFeaturedPhoto)} */}
                                  {
                                    getSingleCarFeaturedPhoto && Object.keys(getSingleCarFeaturedPhoto).length > 0?
                                    (
                                      <a className={"featuredImage"} data-fancybox="gallery" href={getSingleCarFeaturedPhoto.link}>
                                        <img
                                          alt={getSingleCarFeaturedPhoto.name}
                                          src={getSingleCarFeaturedPhoto.link}
                                        />
                                      </a>
                                    ):('')
                                  }
                                  <div className="galleryScroll">
                                    {getSingleCarPhotos.map((image, index) => (
                                      <>
                                        <a className={"galleryItem"} data-fancybox="gallery" href={image.link}>
                                          <img
                                            alt={image.name}
                                            src={image.link}
                                          />
                                        </a>
                                      </>
                                    ))}
                                  </div>
                                </>
                              ):(
                                <a className={"featuredImage"} data-fancybox="gallery" href={defaultCarImage}>
                                  <img
                                    alt={defaultCarImage}
                                    src={defaultCarImage}
                                  />
                                </a>
                              )
                            }
                          </div>
                        </Fancybox>
                      </div>

                      <div class="carPhotosGallery" key={getKey}>
                        {/* {getSingleCarPhotos.gallery.map((image, index) => (
                          <>
                            <SideBySideMagnifier
                              className="carPhoto"
                              imageSrc={image.src}
                              imageAlt="Example"
                              largeImageSrc={image.src} // Optional
                              autoInPlace={false}
                              fillAvailableSpace={false}
                              magnifierSize="100%"
                              previewSizePercentage="100"
                              size="300"
                              overlayBoxImageSize="300"
                              magnifierBorderSize="10"
                            />
                          </>
                        ))} */}
                      </div>
                      {/* <div class="row gutters" style={{clear: "both"}}>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                          <div class="col text-center">
                            {!showOfferModal ?
                              (
                                Object.keys(singleCarOffer).length != 0 ?
                                  (
                                    <>
                                      <div className="mb-3">
                                        <strong>
                                          {singleCarOffer.offer_price ? singleCarOffer.offer_price : ''}
                                        </strong>
                                      </div>

                                      {
                                        singleCarData.offer?
                                        (
                                          singleCarData.offer.status == 'pending'?
                                          (
                                            <button
                                              type="button"
                                              id="submit"
                                              name="submit"
                                              class="btn btn-primary"
                                              onClick={MakeOffer}
                                            >
                                              Change Offer
                                            </button>
                                          ):(
                                            singleCarData.offer.status == 'accepted'?
                                            (
                                              <div class="alert alert-success" role="alert">Your offer has been accepted by car customer now you can not change offer price any more.</div>
                                            ):(
                                              <div class="alert alert-danger" role="alert">Your offer has been rejected by car customer now you can not change offer price any more.</div>
                                            )
                                          )
                                        ):('')
                                      }
                                    </>
                                  ) : (
                                    <button
                                      type="button"
                                      id="submit"
                                      name="submit"
                                      class="btn btn-primary"
                                      onClick={MakeOffer}
                                    >
                                      Make an Offer
                                    </button>
                                  )
                              ) : ('')
                            }
                            {showOfferModal &&
                              <div>
                              <form>
                                <div class="form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="offerPrice"
                                    value={offerPrice}
                                    // ref={offerPrice}
                                    onChange={e => setOfferPrice(e.target.value)}
                                  />
                                </div>
                                {
                                  Object.keys(singleCarOffer).length != 0 ?
                                    (
                                      <button type="submit" className="btn btn-primary btn-block mt-2" onClick={SubmitOfferBtn} disabled={btnLoading}>
                                        {
                                          btnLoading ?
                                            (
                                              <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                              <>Update Offer</>
                                            )
                                        }
                                      </button>
                                    ) : (
                                      <button type="submit" className="btn btn-primary btn-block mt-2" onClick={SubmitOfferBtn} disabled={btnLoading}>
                                        {
                                          btnLoading ?
                                            (
                                              <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                              <>Submit Offer</>
                                            )
                                        }
                                      </button>
                                    )
                                }
                              </form>
                              </div>
                            }
                          </div>
                        </div>
                      </div> */}
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mb-2 text-primary">Vehicle Details</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="make">Make: </label>
                        <div className="in">
                          <input type="text" className="form-control-plaintext text-bold-700" id="make" value={singleCarData.make} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="model">Model: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="model" value={singleCarData.model} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="model_year">Model Year: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="model_year" value={singleCarData.model_year} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="Manufacturer">Manufacturer: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="Manufacturer" name="Manufacturer" value={singleCarDetails.Manufacturer ? singleCarDetails.Manufacturer : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="VehicleType">Vechile Type: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="VehicleType" value={singleCarDetails.VehicleType ? singleCarDetails.VehicleType : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="Seats">Total Seats: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="Seats" value={singleCarDetails.Seats ? singleCarDetails.Seats : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="PlantCountry">Plant Country: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="PlantCountry" value={singleCarDetails.PlantCountry ? singleCarDetails.PlantCountry : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="v">Body Class: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="BodyClass" value={singleCarDetails.BodyClass ? singleCarDetails.BodyClass : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="TransmissionStyle">Transmission Style: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="TransmissionStyle" value={singleCarDetails.TransmissionStyle ? singleCarDetails.TransmissionStyle : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="DriveType">Drive Type: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="DriveType" value={singleCarDetails.DriveType ? singleCarDetails.DriveType : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="TopSpeedMPH">Top Speed MPH: </label>
                        <div className="in">
                          <input type="text" class="form-control-plaintext text-bold-700" id="TopSpeedMPH" value={singleCarDetails.TopSpeedMPH ? singleCarDetails.TopSpeedMPH : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Engine</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="EngineModel">Engine Model: </label>
                        <div className="in">
                          <input type="name" class="form-control-plaintext text-bold-700" id="EngineModel" value={singleCarDetails.EngineModel ? singleCarDetails.EngineModel : 'N/A'} disabled />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="engine_power">Engine Power: </label>
                        <div className="in">
                          <input type="name" class="form-control-plaintext text-bold-700" id="engine_power" value={''} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 class="mt-3 mb-2 text-primary">Location</h6>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="d-flex form-group">
                        <label className="col-form-label pr-2" for="engine_power">Location: </label>
                        <div className="in">
                          <input type="text" className="form-control-plaintext text-bold-700" id="fullName" value={singleCarData.location} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default AdminCars;
